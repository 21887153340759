<template>
  <div class="container">
    <div class="d-flex justify-content-center h-100">
      <div class="card">
        <div class="card-header">
          <h3>Sign In</h3>
        </div>
        <div class="card-body">
          <template v-if="!userInfo">
            <template v-for="provider in providers" :key="provider">
              <a
                type="button"
                class="btn btn-primary btn-lg btn-block"                
                :href="`/.auth/login/${provider}?post_login_redirect_uri=${redirect}`"
                >{{ provider }}</a
              >
            </template>
          </template>
          <a
            class="btn btn-primary btn-lg btn-block"
            v-if="userInfo"
            :href="`/.auth/logout?post_logout_redirect_uri=${redirect}`"
            >Logout</a
          >
          <div class="user" v-if="userInfo">
            <p class="text-white">Welcome</p>
            <p class="text-white">{{ userInfo.userDetails }}</p>
            <p class="text-white">{{ userInfo.identityProvider }}</p>
            <p class="text-white" >{{ userInfo }}</p>
          </div>
        </div>
        <a
          type="button"
          class="btn btn-primary btn-lg btn-block"
          @click="getInfo"
          >Get Info</a
        >
        <p class="text-white">{{ info }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userInfo: {
        type: Object,
        default() {},
      },
      providers: ['twitter', 'github', 'aad'],
      redirect: window.location.pathname,
      info:null
    };
  },
  methods: {
    async getUserInfo() {
      try {
        const response = await fetch('/.auth/me');
        const payload = await response.json();
        const { clientPrincipal } = payload;
        return clientPrincipal;
      } catch (error) {
        console.error('No profile could be found');
        return undefined;
      }
    },
    async getInfo() {
      try {
        const response = await fetch("https://graph.microsoft.com/v1.0/me");
        this.info = await response.json();
        console.log(this.info);
      } catch (error) {
        console.log(error);
        this.info = error;
        console.error("error could be found");
      }
    },
  },
  async created() {
    this.userInfo = await this.getUserInfo();
    console.log('userInfo', this.userInfo);
  },
};
</script>

<style>
@import url('https://fonts.googleapis.com/css?family=Numans');

html,
body {
  background-image: url('http://getwallpapers.com/wallpaper/full/a/5/d/544750.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  font-family: 'Numans', sans-serif;
}

.container {
  height: 100%;
  align-content: center;
}

.card {
  height: 370px;
  margin-top: auto;
  margin-bottom: auto;
  width: 400px;
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.social_icon span {
  font-size: 60px;
  margin-left: 10px;
  color: #ffc312;
}

.social_icon span:hover {
  color: white;
  cursor: pointer;
}

.card-header h3 {
  color: white;
}

.social_icon {
  position: absolute;
  right: 20px;
  top: -45px;
}

.input-group-prepend span {
  width: 50px;
  background-color: #ffc312;
  color: black;
  border: 0 !important;
}

input:focus {
  outline: 0 0 0 0 !important;
  box-shadow: 0 0 0 0 !important;
}

.remember {
  color: white;
}

.remember input {
  width: 20px;
  height: 20px;
  margin-left: 15px;
  margin-right: 5px;
}

.login_btn {
  color: black;
  background-color: #ffc312;
  width: 100px;
}

.login_btn:hover {
  color: black;
  background-color: white;
}

.links {
  color: white;
}

.links a {
  margin-left: 4px;
}
</style>
